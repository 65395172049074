import {
  Button,
  Form,
  InputNumber,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from '@douyinfe/semi-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { CHANNEL_OPTIONS, PageSize } from '../constants';
import { API, showError, showInfo, showSuccess } from '../helpers';
import {
  renderGroup,
  renderNumberWithPoint,
  renderQuota,
} from '../helpers/render';
import EditChannel from '../pages/Channel/EditChannel';

const ChannelType = (type) => {
  const chtype = CHANNEL_OPTIONS.find((item) => item.key === type);
  return (
    <Tag size='large' color={chtype.color}>
      {chtype.text}
    </Tag>
  );
};

const ChannelsTable = () => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '分组',
      dataIndex: 'group',
      render: (text, record, index) => {
        return (
          <Space spacing={2}>
            {text.split(',').map((item, index) => renderGroup(item))}
          </Space>
        );
      },
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (text, record, index) => {
        return <div>{ChannelType(text)}</div>;
      },
    },
    {
      title: '计费类型',
      dataIndex: 'pay_type',
      width: 100,
      render: (text, record, index) => {
        return (
          <Tag color={text === 1 ? 'yellow' : 'red'}>
            {text === 1 ? '计费' : '免费'}
          </Tag>
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text, record, index) => {
        return <div>{renderStatus(text)}</div>;
      },
    },
    {
      title: '响应时间',
      dataIndex: 'response_time',
      render: (text, record, index) => {
        return <div>{renderResponseTime(text)}</div>;
      },
    },
    {
      title: '已用/剩余',
      dataIndex: 'expired_time',
      render: (text, record, index) => {
        return (
          <div>
            <Space spacing={1}>
              <Tooltip content={'已用额度'}>
                <Tag color='white' type='ghost' size='large'>
                  {renderQuota(record.used_quota)}
                </Tag>
              </Tooltip>
              <Tooltip content={'剩余额度' + record.balance + '，点击更新'}>
                <Tag
                  color='white'
                  type='ghost'
                  size='large'
                  onClick={() => {
                    updateChannelBalance(record);
                  }}
                >
                  ${renderNumberWithPoint(record.balance)}
                </Tag>
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
    {
      title: '优先级',
      dataIndex: 'priority',
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              style={{ width: 70 }}
              name='priority'
              onChange={(value) => {
                manageChannel(record.id, 'priority', record, value);
              }}
              defaultValue={record.priority}
              min={-999}
            />
          </div>
        );
      },
    },
    {
      title: '权重',
      dataIndex: 'weight',
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              style={{ width: 70 }}
              name='weight'
              onChange={(value) => {
                manageChannel(record.id, 'weight', record, value);
              }}
              defaultValue={record.weight}
              min={0}
            />
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'operate',
      render: (text, record, index) => (
        <div>
          <Button
            theme='light'
            type='primary'
            style={{ marginRight: 1 }}
            onClick={() => testChannel(record)}
          >
            测试
          </Button>
          <Popconfirm
            title='确定是否要删除此渠道？'
            content='此修改将不可逆'
            okType={'danger'}
            position={'left'}
            onConfirm={() => {
              manageChannel(record.id, 'delete', record);
            }}
          >
            <Button theme='light' type='danger' style={{ marginRight: 1 }}>
              删除
            </Button>
          </Popconfirm>
          {record.status === 1 ? (
            <Button
              theme='light'
              type='warning'
              style={{ marginRight: 1 }}
              onClick={async () => {
                manageChannel(record.id, 'disable', record);
              }}
            >
              禁用
            </Button>
          ) : (
            <Button
              theme='light'
              type='secondary'
              style={{ marginRight: 1 }}
              onClick={async () => {
                manageChannel(record.id, 'enable', record);
              }}
            >
              启用
            </Button>
          )}
          <Button
            theme='light'
            type='tertiary'
            style={{ marginRight: 1 }}
            onClick={() => {
              setEditingChannel(record);
              setShowEdit(true);
            }}
          >
            编辑
          </Button>
        </div>
      ),
    },
  ];

  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [idSort, setIdSort] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchGroup, setSearchGroup] = useState('');
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [channelCount, setChannelCount] = useState(0);
  const [groupOptions, setGroupOptions] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editingChannel, setEditingChannel] = useState({
    id: undefined,
  });

  const loadChannels = useCallback(async (startIdx, idSort) => {
    setLoading(true);
    const res = await API.get(
      `/api/channel/?p=${startIdx}&page_size=${PageSize}&id_sort=${idSort}`
    );
    const { success, message, data, total } = res.data;
    if (success) {
      setChannels(data || []);
      setChannelCount(total || 0);
    } else {
      showError(message);
    }
    setLoading(false);
  }, []);

  const refresh = useCallback(() => {
    loadChannels(activePage - 1, idSort);
  }, [activePage, idSort, loadChannels]);

  useEffect(() => {
    const localIdSort = localStorage.getItem('id-sort') === 'true';
    setIdSort(localIdSort);
    loadChannels(0, localIdSort);
    fetchGroups();
  }, [loadChannels]);

  const manageChannel = useCallback(
    async (id, action, record, value) => {
      let data = { id };
      let res;
      switch (action) {
        case 'delete':
          res = await API.delete(`/api/channel/${id}/`);
          break;
        case 'enable':
          data.status = 1;
          res = await API.put('/api/channel/', data);
          break;
        case 'disable':
          data.status = 2;
          res = await API.put('/api/channel/', data);
          break;
        case 'priority':
          if (value === '') {
            return;
          }
          data.priority = parseInt(value);
          res = await API.put('/api/channel/', data);
          break;
        case 'weight':
          if (value === '') {
            return;
          }
          data.weight = parseInt(value);
          if (data.weight < 0) {
            data.weight = 0;
          }
          res = await API.put('/api/channel/', data);
          break;
        default:
      }
      const { success, message } = res.data;
      if (success) {
        refresh();
        showSuccess('操作成功完成！');
      } else {
        showError(message);
      }
    },
    [refresh]
  );

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return (
          <Tag size='large' color='green'>
            已启用
          </Tag>
        );
      case 2:
        return (
          <Popup
            trigger={
              <Tag size='large' color='red'>
                已禁用
              </Tag>
            }
            content='本渠道被手动禁用'
            basic
          />
        );
      case 3:
        return (
          <Popup
            trigger={
              <Tag size='large' color='yellow'>
                已禁用
              </Tag>
            }
            content='本渠道被程序自动禁用'
            basic
          />
        );
      default:
        return (
          <Tag size='large' color='grey'>
            未知状态
          </Tag>
        );
    }
  };

  const renderResponseTime = (responseTime) => {
    let time = responseTime / 1000;
    time = time.toFixed(2) + ' 秒';
    if (responseTime === 0) {
      return (
        <Tag size='large' color='grey'>
          未测试
        </Tag>
      );
    } else if (responseTime <= 1000) {
      return (
        <Tag size='large' color='green'>
          {time}
        </Tag>
      );
    } else if (responseTime <= 3000) {
      return (
        <Tag size='large' color='lime'>
          {time}
        </Tag>
      );
    } else if (responseTime <= 5000) {
      return (
        <Tag size='large' color='yellow'>
          {time}
        </Tag>
      );
    } else {
      return (
        <Tag size='large' color='red'>
          {time}
        </Tag>
      );
    }
  };

  const searchChannels = useCallback(
    async (searchKeyword, searchGroup) => {
      if (searchKeyword === '' && searchGroup === '') {
        // if keyword is blank, load files instead.
        await loadChannels(0, idSort);
        setActivePage(1);
        return;
      }
      const res = await API.get(
        `/api/channel/search?keyword=${searchKeyword}&group=${searchGroup}`
      );
      const { success, message, data, total } = res.data;
      if (success) {
        setChannels(data);
        setChannelCount(total);
        setActivePage(1);
      } else {
        showError(message);
      }
    },
    [idSort, loadChannels]
  );

  const testChannel = useCallback(
    async (record) => {
      const res = await API.get(`/api/channel/test/${record.id}/`);
      const { success, message, time } = res.data;
      if (success) {
        let newChannels = [...channels];
        record.response_time = time * 1000;
        record.test_time = Date.now() / 1000;
        setChannels(newChannels);
        showInfo(`通道 ${record.name} 测试成功，耗时 ${time.toFixed(2)} 秒。`);
      } else {
        showError(message);
      }
    },
    [channels]
  );

  const testAllChannels = async () => {
    const res = await API.get(`/api/channel/test`);
    const { success, message } = res.data;
    if (success) {
      showInfo('已成功开始测试所有已启用通道，请刷新页面查看结果。');
    } else {
      showError(message);
    }
  };

  const deleteAllDisabledChannels = async () => {
    const res = await API.delete(`/api/channel/disabled`);
    const { success, message, data } = res.data;
    if (success) {
      showSuccess(`已删除所有禁用渠道，共计 ${data} 个`);
      refresh();
    } else {
      showError(message);
    }
  };

  const updateChannelBalance = async (record) => {
    const res = await API.get(`/api/channel/update_balance/${record.id}/`);
    const { success, message, balance } = res.data;
    if (success) {
      record.balance = balance;
      record.balance_updated_time = Date.now() / 1000;
      showInfo(`通道 ${record.name} 余额更新成功！`);
      refresh();
    } else {
      showError(message);
    }
  };

  const updateAllChannelsBalance = async () => {
    const res = await API.get(`/api/channel/update_balance`);
    const { success, message } = res.data;
    if (success) {
      showInfo('已更新完毕所有已启用通道余额！');
    } else {
      showError(message);
    }
  };

  const batchDeleteChannels = async () => {
    if (selectedChannels.length === 0) {
      showError('请先选择要删除的通道！');
      return;
    }
    setLoading(true);
    let ids = [];
    selectedChannels.forEach((channel) => {
      ids.push(channel.id);
    });
    const res = await API.post(`/api/channel/batch`, { ids: ids });
    const { success, message, data } = res.data;
    if (success) {
      showSuccess(`已删除 ${data} 个通道！`);
      refresh();
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    loadChannels(page - 1, idSort);
  };

  const fetchGroups = async () => {
    try {
      let res = await API.get(`/api/group/`);
      // add 'all' option
      // res.data.data.unshift('all');
      setGroupOptions(
        res.data.data.map((group) => ({
          label: group,
          value: group,
        }))
      );
    } catch (error) {
      showError(error.message);
    }
  };

  const closeEdit = () => {
    setShowEdit(false);
  };

  const handleRow = (record, index) => {
    if (record.status !== 1) {
      return {
        style: {
          background: 'var(--semi-color-disabled-border)',
        },
      };
    } else {
      return {};
    }
  };

  return (
    <>
      <EditChannel
        refresh={refresh}
        visible={showEdit}
        handleClose={closeEdit}
        editingChannel={editingChannel}
      />
      <Form
        onSubmit={() => {
          searchChannels(searchKeyword, searchGroup);
        }}
        labelPosition='left'
      >
        <div style={{ display: 'flex' }}>
          <Space>
            <Form.Input
              field='search'
              label='关键词'
              placeholder='ID，名称和密钥 ...'
              value={searchKeyword}
              onChange={(v) => {
                setSearchKeyword(v.trim());
              }}
            />
            <Form.Select
              field='group'
              label='分组'
              optionList={groupOptions}
              onChange={(v) => {
                setSearchGroup(v);
                searchChannels(searchKeyword, v);
              }}
            />
          </Space>
        </div>
      </Form>

      <Table
        columns={columns}
        rowKey='id'
        dataSource={channels}
        pagination={{
          currentPage: activePage,
          pageSize: PageSize,
          total: channelCount,
          pageSizeOpts: [10, 20, 50, 100],
          onPageChange: handlePageChange,
        }}
        loading={loading}
        onRow={handleRow}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(
              `selectedRowKeys: ${selectedRowKeys}`,
              'selectedRows: ',
              selectedRows
            );
            setSelectedChannels(selectedRows);
          },
        }}
      />

      <Space style={{ pointerEvents: 'auto' }}>
        <Typography.Text strong>使用ID排序</Typography.Text>
        <Switch
          checked={idSort}
          label='使用ID排序'
          uncheckedText='关'
          aria-label='是否用ID排序'
          onChange={(v) => {
            localStorage.setItem('id-sort', v + '');
            setIdSort(v);
            loadChannels(0, v);
          }}
        ></Switch>
        <Button
          theme='light'
          type='primary'
          style={{ marginRight: 8 }}
          onClick={() => {
            setEditingChannel({
              id: undefined,
            });
            setShowEdit(true);
          }}
        >
          添加渠道
        </Button>
        <Popconfirm
          title='确定？'
          okType={'warning'}
          onConfirm={testAllChannels}
          position={'top'}
        >
          <Button theme='light' type='warning' style={{ marginRight: 8 }}>
            测试所有已启用通道
          </Button>
        </Popconfirm>
        <Popconfirm
          title='确定？'
          okType={'secondary'}
          onConfirm={updateAllChannelsBalance}
        >
          <Button theme='light' type='secondary' style={{ marginRight: 8 }}>
            更新所有已启用通道余额
          </Button>
        </Popconfirm>
        <Popconfirm
          title='确定是否要删除禁用通道？'
          content='此修改将不可逆'
          okType={'danger'}
          onConfirm={deleteAllDisabledChannels}
        >
          <Button theme='light' type='danger' style={{ marginRight: 8 }}>
            删除禁用通道
          </Button>
        </Popconfirm>
        <Popconfirm
          title='确定是否要删除所选通道？'
          content='删除后无法恢复'
          okType={'danger'}
          onConfirm={batchDeleteChannels}
          position='top'
        >
          <Button theme='light' type='danger' style={{ marginRight: 8 }}>
            删除所选通道
          </Button>
        </Popconfirm>

        <Button
          theme='light'
          type='primary'
          style={{ marginRight: 8 }}
          onClick={refresh}
        >
          刷新
        </Button>
      </Space>
    </>
  );
};

export default ChannelsTable;
