import {
  Button,
  Dropdown,
  Form,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from '@douyinfe/semi-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { PageSize } from '../constants';
import { API, showError, showSuccess, timestamp2string } from '../helpers';
import { renderGroup, renderNumber, renderQuota } from '../helpers/render';
import AddUser from '../pages/User/AddUser';
import EditUser from '../pages/User/EditUser';

function renderRole(role) {
  switch (role) {
    case 1:
      return <Tag size='large'>普通用户</Tag>;
    case 10:
      return (
        <Tag color='yellow' size='large'>
          管理员
        </Tag>
      );
    case 100:
      return (
        <Tag color='orange' size='large'>
          超级管理员
        </Tag>
      );
    default:
      return (
        <Tag color='red' size='large'>
          未知身份
        </Tag>
      );
  }
}

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searching, setSearching] = useState(false);
  const [userCount, setUserCount] = useState(PageSize);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [editingUser, setEditingUser] = useState({
    id: undefined,
  });

  const loadUsers = async (startIdx) => {
    const res = await API.get(`/api/user/?p=${startIdx}`);
    const { success, message, data, total } = res.data;
    if (success) {
      setUsers(data || []);
      setUserCount(total || 0);
    } else {
      showError(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUsers(0);
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return <Tag size='large'>已激活</Tag>;
      case 2:
        return (
          <Tag size='large' color='red'>
            已封禁
          </Tag>
        );
      default:
        return (
          <Tag size='large' color='grey'>
            未知状态
          </Tag>
        );
    }
  };

  const searchUsers = useCallback(async () => {
    if (searchKeyword === '') {
      // if keyword is blank, load files instead.
      await loadUsers(0);
      setActivePage(1);
      return;
    }
    setSearching(true);
    const res = await API.get(`/api/user/search?keyword=${searchKeyword}`);
    const { success, message, data, total } = res.data;
    if (success) {
      setUsers(data || []);
      setUserCount(total || 0);
      setActivePage(1);
    } else {
      showError(message);
    }
    setSearching(false);
  }, [searchKeyword]);

  const handleKeywordChange = async (value) => {
    setSearchKeyword(value.trim());
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    loadUsers(page - 1);
  };

  const closeAddUser = () => {
    setShowAddUser(false);
  };

  const closeEditUser = () => {
    setShowEditUser(false);
    setEditingUser({
      id: undefined,
    });
  };

  const refresh = useCallback(async () => {
    if (searchKeyword === '') {
      await loadUsers(activePage - 1);
    } else {
      await searchUsers();
    }
  }, [activePage, searchKeyword, searchUsers]);

  const manageUser = useCallback(
    async (username, action, record) => {
      const res = await API.post('/api/user/manage', {
        username,
        action,
      });
      const { success, message } = res.data;
      if (success) {
        refresh();
        showSuccess('操作完成！');
      } else {
        showError(message);
      }
    },
    [refresh]
  );

  const subDropdown = useCallback(
    (record) => (
      <Dropdown.Menu>
        <Dropdown.Item>
          <Popconfirm
            title='确定？'
            okType={'warning'}
            onConfirm={() => {
              manageUser(record.username, 'promote', record);
            }}
          >
            提升
          </Popconfirm>
        </Dropdown.Item>
        <Dropdown.Item type='secondary'>
          <Popconfirm
            title='确定？'
            okType={'warning'}
            onConfirm={() => {
              manageUser(record.username, 'demote', record);
            }}
          >
            降级
          </Popconfirm>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => {
            setEditingUser(record);
            setShowEditUser(true);
          }}
        >
          编辑
        </Dropdown.Item>
      </Dropdown.Menu>
    ),
    [manageUser]
  );

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    // },
    {
      title: '用户名',
      dataIndex: 'username',
    },
    {
      title: '分组',
      dataIndex: 'group',
      render: (text) => {
        return <div>{renderGroup(text)}</div>;
      },
    },
    {
      title: '统计信息',
      dataIndex: 'info',
      render: (_text, record) => {
        return (
          <div>
            <Space spacing={1}>
              <Tooltip content={'剩余额度'}>
                <Tag color='white' size='large'>
                  {renderQuota(record.quota)}
                </Tag>
              </Tooltip>
              <Tooltip content={'已用额度'}>
                <Tag color='white' size='large'>
                  {renderQuota(record.used_quota)}
                </Tag>
              </Tooltip>
              <Tooltip content={'调用次数'}>
                <Tag color='white' size='large'>
                  {renderNumber(record.request_count)}
                </Tag>
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'created_time',
      render: (text) => {
        return <div>{text ? timestamp2string(text) : '-'}</div>;
      },
    },
    {
      title: '角色',
      dataIndex: 'role',
      render: (text) => {
        return <div>{renderRole(text)}</div>;
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text) => {
        return <div>{renderStatus(text)}</div>;
      },
    },
    {
      title: '操作',
      dataIndex: 'operate',
      render: (text, record) => (
        <div>
          <Popconfirm
            title='确定是否要删除此用户？'
            content='此修改将不可逆'
            okType={'danger'}
            position={'left'}
            onConfirm={() => {
              manageUser(record.username, 'delete', record);
            }}
          >
            <Button theme='light' type='danger' style={{ marginRight: 1 }}>
              删除
            </Button>
          </Popconfirm>
          {record.DeletedAt === null && (
            <>
              {record.status === 1 ? (
                <Button
                  theme='light'
                  type='warning'
                  style={{ marginRight: 1 }}
                  onClick={async () => {
                    manageUser(record.username, 'disable', record);
                  }}
                >
                  禁用
                </Button>
              ) : (
                <Button
                  theme='light'
                  type='secondary'
                  style={{ marginRight: 1 }}
                  onClick={async () => {
                    manageUser(record.username, 'enable', record);
                  }}
                  disabled={record.status === 3}
                >
                  启用
                </Button>
              )}
              <Dropdown render={subDropdown(record)}>
                <Button theme='light' type='tertiary'>
                  更多
                </Button>
              </Dropdown>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <AddUser
        refresh={refresh}
        visible={showAddUser}
        handleClose={closeAddUser}
      ></AddUser>
      <EditUser
        refresh={refresh}
        visible={showEditUser}
        handleClose={closeEditUser}
        editingUser={editingUser}
      ></EditUser>
      <Form onSubmit={searchUsers}>
        <Form.Input
          label='搜索关键字'
          icon='search'
          field='keyword'
          iconPosition='left'
          placeholder='搜索用户的 ID，用户名，显示名称，以及邮箱地址 ...'
          value={searchKeyword}
          loading={searching}
          onChange={(value) => handleKeywordChange(value)}
        />
      </Form>

      <Table
        columns={columns}
        dataSource={users}
        pagination={{
          currentPage: activePage,
          pageSize: PageSize,
          total: userCount,
          pageSizeOpts: [10, 20, 50, 100],
          onPageChange: handlePageChange,
        }}
        loading={loading}
      />
      <Button
        theme='light'
        type='primary'
        style={{ marginRight: 8 }}
        onClick={() => {
          setShowAddUser(true);
        }}
      >
        添加用户
      </Button>
    </>
  );
};

export default UsersTable;
