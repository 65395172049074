export const CHANNEL_OPTIONS = [
  { key: 1, text: 'OpenAI', value: 1, color: 'green', label: 'OpenAI' },
  {
    key: 2,
    text: 'Midjourney Proxy',
    value: 2,
    color: 'light-blue',
    label: 'Midjourney Proxy',
  },
  {
    key: 14,
    text: 'Anthropic Claude',
    value: 14,
    color: 'black',
    label: 'Anthropic Claude',
  },
  {
    key: 3,
    text: 'Azure OpenAI',
    value: 3,
    color: 'olive',
    label: 'Azure OpenAI',
  },
  {
    key: 11,
    text: 'Google PaLM2',
    value: 11,
    color: 'orange',
    label: 'Google PaLM2',
  },
  {
    key: 24,
    text: 'Google Gemini',
    value: 24,
    color: 'orange',
    label: 'Google Gemini',
  },
  {
    key: 15,
    text: '百度文心千帆',
    value: 15,
    color: 'blue',
    label: '百度文心千帆',
  },
  {
    key: 17,
    text: '阿里通义千问',
    value: 17,
    color: 'orange',
    label: '阿里通义千问',
  },
  {
    key: 18,
    text: '讯飞星火认知',
    value: 18,
    color: 'blue',
    label: '讯飞星火认知',
  },
  {
    key: 16,
    text: '智谱 ChatGLM',
    value: 16,
    color: 'violet',
    label: '智谱 ChatGLM',
  },
  { key: 19, text: '360 智脑', value: 19, color: 'blue', label: '360 智脑' },
  { key: 23, text: '腾讯混元', value: 23, color: 'teal', label: '腾讯混元' },
  { key: 8, text: '自定义渠道', value: 8, color: 'pink', label: '自定义渠道' },
  {
    key: 22,
    text: '知识库：FastGPT',
    value: 22,
    color: 'blue',
    label: '知识库：FastGPT',
  },
  {
    key: 21,
    text: '知识库：AI Proxy',
    value: 21,
    color: 'purple',
    label: '知识库：AI Proxy',
  },
];
