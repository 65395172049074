import React, { useCallback, useEffect, useState } from 'react';
import {
  API,
  copy,
  isAdmin,
  showError,
  showSuccess,
  timestamp2string,
} from '../helpers';

import {
  Avatar,
  Button,
  Form,
  Layout,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from '@douyinfe/semi-ui';
import { PageSize } from '../constants';
import { renderNumber, renderQuota, stringToColor } from '../helpers/render';

const { Header } = Layout;

// const MODE_OPTIONS = [
//   { key: 'all', text: '全部用户', value: 'all' },
//   { key: 'self', text: '当前用户', value: 'self' },
// ];

const colors = [
  'amber',
  'blue',
  'cyan',
  'green',
  'grey',
  'indigo',
  'light-blue',
  'lime',
  'orange',
  'pink',
  'purple',
  'red',
  'teal',
  'violet',
  'yellow',
];

function renderType(type) {
  switch (type) {
    case 1:
      return (
        <Tag color='cyan' size='large'>
          充值
        </Tag>
      );
    case 2:
      return (
        <Tag color='lime' size='large'>
          消费
        </Tag>
      );
    case 3:
      return (
        <Tag color='orange' size='large'>
          管理
        </Tag>
      );
    case 4:
      return (
        <Tag color='purple' size='large'>
          系统
        </Tag>
      );
    default:
      return (
        <Tag color='black' size='large'>
          未知
        </Tag>
      );
  }
}

function renderUseTime(type) {
  const time = parseInt(type);
  if (time < 101) {
    return (
      <Tag color='green' size='large'>
        {time} s
      </Tag>
    );
  } else if (time < 300) {
    return (
      <Tag color='orange' size='large'>
        {time} s
      </Tag>
    );
  } else {
    return (
      <Tag color='red' size='large'>
        {time} s
      </Tag>
    );
  }
}

function renderIsStream(bool) {
  if (bool) {
    return (
      <Tag color='blue' size='large'>
        流
      </Tag>
    );
  } else {
    return (
      <Tag color='purple' size='large'>
        非流
      </Tag>
    );
  }
}

const LogsTable = () => {
  const isAdminUser = isAdmin();

  const columns = [
    {
      title: '时间',
      dataIndex: 'created_at',
      render: (text, record, index) => {
        return <div>{timestamp2string(text)}</div>;
      },
    },
    {
      title: '渠道',
      dataIndex: 'channel',
      className: isAdminUser ? 'tableShow' : 'tableHiddle',
      render: (text, record, index) => {
        return isAdminUser ? (
          record.type === 0 || record.type === 2 ? (
            <div>
              {
                <Tag
                  color={colors[parseInt(text) % colors.length]}
                  size='large'
                >
                  {text}
                </Tag>
              }
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        );
      },
    },
    {
      title: '用户',
      dataIndex: 'username',
      className: isAdminUser ? 'tableShow' : 'tableHiddle',
      render: (text, record, index) => {
        return isAdminUser ? (
          <div>
            <Avatar
              size='small'
              color={stringToColor(text)}
              style={{ marginRight: 4 }}
              onClick={() => showUserInfo(record.user_id)}
            >
              {typeof text === 'string' && text.slice(0, 1)}
            </Avatar>
            {text}
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      title: '令牌',
      dataIndex: 'token_name',
      render: (text, record, index) => {
        return record.type === 0 || record.type === 2 ? (
          <div>
            <Tag
              color='grey'
              size='large'
              onClick={() => {
                copyText(text);
              }}
            >
              {text}
            </Tag>
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (text, record, index) => {
        return <div>{renderType(text)}</div>;
      },
    },
    {
      title: '模型',
      dataIndex: 'model_name',
      render: (text, record, index) => {
        return record.type === 0 || record.type === 2 ? (
          <div>
            <Tag
              color={stringToColor(text)}
              size='large'
              onClick={() => {
                copyText(text);
              }}
            >
              {text}
            </Tag>
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      title: '用时',
      dataIndex: 'use_time',
      render: (text, record, index) => {
        return (
          <div>
            <Space>
              {renderUseTime(text)}
              {renderIsStream(record.is_stream)}
            </Space>
          </div>
        );
      },
    },
    {
      title: '提示',
      dataIndex: 'prompt_tokens',
      render: (text, record, index) => {
        return record.type === 0 || record.type === 2 ? (
          <div>{<span> {text} </span>}</div>
        ) : (
          <></>
        );
      },
    },
    {
      title: '补全',
      dataIndex: 'completion_tokens',
      render: (text, record, index) => {
        return parseInt(text) > 0 &&
          (record.type === 0 || record.type === 2) ? (
          <div>{<span> {text} </span>}</div>
        ) : (
          <></>
        );
      },
    },
    {
      title: '花费',
      dataIndex: 'quota',
      render: (text, record, index) => {
        return record.type === 0 || record.type === 2 ? (
          <div>{renderQuota(text, 6)}</div>
        ) : (
          <></>
        );
      },
    },
    isAdminUser && {
      title: '详情',
      dataIndex: 'content',
      render: (text) => (
        <Tooltip position='topRight' content={text}>
          查看
        </Tooltip>
      ),
    },
    isAdminUser && {
      title: '请求',
      dataIndex: 'requestText',
      render: (text) => (
        <Tooltip position='topRight' content={text}>
          查看
        </Tooltip>
      ),
    },
    isAdminUser && {
      title: '响应',
      dataIndex: 'responseText',
      render: (text) => (
        <Tooltip position='topRight' content={text}>
          查看
        </Tooltip>
      ),
    },
  ].filter(Boolean);

  const [logs, setLogs] = useState([]);
  const [showStat, setShowStat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [logCount, setLogCount] = useState(PageSize);
  const [logType, setLogType] = useState(0);

  let now = new Date();
  // 初始化start_timestamp为前一天
  const [inputs, setInputs] = useState({
    username: '',
    token_name: '',
    model_name: '',
    start_timestamp: timestamp2string(now.getTime() / 1000 - 86400),
    end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
    channel: '',
  });
  const {
    username,
    token_name,
    model_name,
    start_timestamp,
    end_timestamp,
    channel,
  } = inputs;

  const [stat, setStat] = useState({
    quota: 0,
    token: 0,
  });

  const handleInputChange = (value, name) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const getLogSelfStat = async () => {
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    let res = await API.get(
      `/api/log/self/stat?type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`
    );
    const { success, message, data } = res.data;
    if (success) {
      setStat(data);
    } else {
      showError(message);
    }
  };

  const getLogStat = async () => {
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    let res = await API.get(
      `/api/log/stat?type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`
    );
    const { success, message, data } = res.data;
    if (success) {
      setStat(data);
    } else {
      showError(message);
    }
  };

  const handleEyeClick = async () => {
    if (!showStat) {
      if (isAdminUser) {
        await getLogStat();
      } else {
        await getLogSelfStat();
      }
    }
    setShowStat(!showStat);
  };

  const showUserInfo = async (userId) => {
    if (!isAdminUser) {
      return;
    }
    const res = await API.get(`/api/user/${userId}`);
    const { success, message, data } = res.data;
    if (success) {
      Modal.info({
        title: '用户信息',
        content: (
          <div style={{ padding: 12 }}>
            <p>用户名: {data.username}</p>
            <p>余额: {renderQuota(data.quota)}</p>
            <p>已用额度：{renderQuota(data.used_quota)}</p>
            <p>请求次数：{renderNumber(data.request_count)}</p>
          </div>
        ),
        centered: true,
      });
    } else {
      showError(message);
    }
  };

  const loadLogs = useCallback(
    async (startIdx) => {
      setLoading(true);

      let url = '';
      let localStartTimestamp = Date.parse(start_timestamp) / 1000;
      let localEndTimestamp = Date.parse(end_timestamp) / 1000;
      if (isAdminUser) {
        url = `/api/log/?p=${startIdx}&type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`;
      } else {
        url = `/api/log/self/?p=${startIdx}&type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
      }
      const res = await API.get(url);
      const { success, message, data, total } = res.data;
      if (success) {
        setLogs(data || []);
        setLogCount(total);
      } else {
        showError(message);
      }
      setLoading(false);
    },
    [
      channel,
      end_timestamp,
      isAdminUser,
      logType,
      model_name,
      start_timestamp,
      token_name,
      username,
    ]
  );

  const handlePageChange = (page) => {
    setActivePage(page);
    loadLogs(page - 1);
  };

  const refresh = useCallback(async () => {
    setActivePage(1);
    await loadLogs(0);
  }, [loadLogs]);

  const copyText = async (text) => {
    if (await copy(text)) {
      showSuccess('已复制：' + text);
    } else {
      Modal.error({ title: '无法复制到剪贴板，请手动复制', content: text });
    }
  };

  useEffect(() => {
    refresh().then();
  }, [logType, refresh]);

  return (
    <>
      <Layout>
        <Header>
          <h3>
            使用明细（总消耗额度：
            {showStat && renderQuota(stat.quota)}
            {!showStat && (
              <span
                onClick={handleEyeClick}
                style={{ cursor: 'pointer', color: 'gray' }}
              >
                点击查看
              </span>
            )}
            ）
          </h3>
        </Header>
        <Form layout='horizontal' style={{ marginTop: 10 }}>
          <>
            <Form.Input
              field='token_name'
              label='令牌名称'
              style={{ width: 176 }}
              value={token_name}
              placeholder={'可选值'}
              name='token_name'
              onChange={(value) => handleInputChange(value, 'token_name')}
            />
            <Form.Input
              field='model_name'
              label='模型名称'
              style={{ width: 176 }}
              value={model_name}
              placeholder='可选值'
              name='model_name'
              onChange={(value) => handleInputChange(value, 'model_name')}
            />
            <Form.DatePicker
              field='start_timestamp'
              label='起始时间'
              style={{ width: 272 }}
              initValue={start_timestamp}
              value={start_timestamp}
              type='dateTime'
              name='start_timestamp'
              onChange={(value) => handleInputChange(value, 'start_timestamp')}
            />
            <Form.DatePicker
              field='end_timestamp'
              fluid
              label='结束时间'
              style={{ width: 272 }}
              initValue={end_timestamp}
              value={end_timestamp}
              type='dateTime'
              name='end_timestamp'
              onChange={(value) => handleInputChange(value, 'end_timestamp')}
            />
            {/*<Form.Button fluid label='操作' width={2} onClick={refresh}>查询</Form.Button>*/}
            {isAdminUser && (
              <>
                <Form.Input
                  field='channel'
                  label='渠道 ID'
                  style={{ width: 176 }}
                  value={channel}
                  placeholder='可选值'
                  name='channel'
                  onChange={(value) => handleInputChange(value, 'channel')}
                />
                <Form.Input
                  field='username'
                  label='用户名称'
                  style={{ width: 176 }}
                  value={username}
                  placeholder={'可选值'}
                  name='username'
                  onChange={(value) => handleInputChange(value, 'username')}
                />
              </>
            )}
            <Form.Section>
              <Button
                label='查询'
                type='primary'
                htmlType='submit'
                className='btn-margin-right'
                onClick={refresh}
              >
                查询
              </Button>
            </Form.Section>
          </>
        </Form>
        <Table
          columns={columns}
          dataSource={logs}
          pagination={{
            currentPage: activePage,
            pageSize: PageSize,
            total: logCount,
            pageSizeOpts: [10, 20, 50, 100],
            onPageChange: handlePageChange,
          }}
          loading={loading}
        />
        <Select
          defaultValue='0'
          style={{ width: 120 }}
          onChange={(value) => {
            setLogType(parseInt(value));
          }}
        >
          <Select.Option value='0'>全部</Select.Option>
          <Select.Option value='1'>充值</Select.Option>
          <Select.Option value='2'>消费</Select.Option>
          <Select.Option value='3'>管理</Select.Option>
          <Select.Option value='4'>系统</Select.Option>
        </Select>
      </Layout>
    </>
  );
};

export default LogsTable;
