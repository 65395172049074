import { useRequest } from 'ahooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  API,
  copy,
  crtUser,
  isAdmin,
  showError,
  showSuccess,
  timestamp2string,
} from '../helpers';

import {
  Button,
  Dropdown,
  Form,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
} from '@douyinfe/semi-ui';
import { PageSize } from '../constants';
import { renderQuota } from '../helpers/render';
import EditToken from '../pages/Token/EditToken';

function renderStatus(status, model_limits_enabled = false) {
  switch (status) {
    case 1:
      if (model_limits_enabled) {
        return (
          <Tag color='green' size='large'>
            已启用：限制模型
          </Tag>
        );
      } else {
        return (
          <Tag color='green' size='large'>
            已启用
          </Tag>
        );
      }
    case 2:
      return (
        <Tag color='red' size='large'>
          已禁用
        </Tag>
      );
    case 3:
      return (
        <Tag color='yellow' size='large'>
          已过期
        </Tag>
      );
    case 4:
      return (
        <Tag color='grey' size='large'>
          已耗尽
        </Tag>
      );
    default:
      return (
        <Tag color='black' size='large'>
          未知状态
        </Tag>
      );
  }
}

const TokensTable = () => {
  const isAdminUser = isAdmin();

  const user = crtUser();
  const [pageSize, setPageSize] = useState(PageSize);
  const [showEdit, setShowEdit] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [tokenCount, setTokenCount] = useState(pageSize);
  const [activePage, setActivePage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [editingToken, setEditingToken] = useState({
    id: undefined,
  });
  const [userList, setUserList] = useState([]);
  const [selectUserid, setSelectUserid] = useState(user.id);

  const { run: getUserlist } = useRequest(
    () => API.get('/api/user/?p=0&size=1000'),
    {
      manual: true,
      onSuccess: (res) => {
        const { success, message, data } = res.data;
        if (success) {
          let list = [{ label: '全部用户', value: 0 }];
          data.forEach((item) => {
            list.push({
              label: item.username,
              value: item.id,
            });
          });
          setUserList(list);
          setSelectUserid(user.id);
        } else {
          showError(message);
        }
      },
    }
  );

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
    },

    isAdminUser && {
      title: '所属用户',
      dataIndex: 'username',
      render: (text) => (
        <Tag size='small' color='violet'>
          {text || user.username}
        </Tag>
      ),
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => {
        return <div>{renderStatus(text, record.model_limits_enabled)}</div>;
      },
    },
    {
      title: '已用额度',
      dataIndex: 'used_quota',
      render: (text, record, index) => {
        return <div>{renderQuota(parseInt(text))}</div>;
      },
    },
    {
      title: '剩余额度',
      dataIndex: 'remain_quota',
      render: (text, record, index) => {
        return (
          <div>
            {record.unlimited_quota ? (
              <Tag size={'large'} color={'white'}>
                无限制
              </Tag>
            ) : (
              <Tag size={'large'} color={'light-blue'}>
                {renderQuota(parseInt(text))}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'created_time',
      render: (text) => timestamp2string(text),
    },
    {
      title: '过期时间',
      dataIndex: 'expired_time',
      render: (text, record) => {
        return (
          <div>
            {record.expired_time === -1 ? '永不过期' : timestamp2string(text)}
          </div>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'operate',
      render: (text, record, index) => (
        <div>
          <Button
            theme='light'
            type='secondary'
            style={{ marginRight: 1 }}
            onClick={async () => {
              onOpenLink('', record.key);
            }}
          >
            聊天
          </Button>
          <Button
            theme='light'
            type='warning'
            style={{ marginRight: 1 }}
            onClick={async () => {
              await copyText('sk-' + record.key);
            }}
          >
            复制
          </Button>
          <Dropdown render={subDropdown(record)}>
            <Button theme='light' type='tertiary'>
              更多
            </Button>
          </Dropdown>
        </div>
      ),
    },
  ].filter(Boolean);

  const closeEdit = () => {
    setShowEdit(false);
    setTimeout(() => {
      setEditingToken({
        id: undefined,
      });
    }, 500);
  };

  const copyText = async (text) => {
    if (await copy(text)) {
      showSuccess('已复制到剪贴板！');
    } else {
      Modal.error({ title: '无法复制到剪贴板，请手动复制', content: text });
    }
  };

  const onOpenLink = async (type, key) => {
    let status = localStorage.getItem('status');
    let serverAddress = '';
    if (status) {
      status = JSON.parse(status);
      serverAddress = status.server_address;
    }
    if (serverAddress === '') {
      serverAddress = window.location.origin;
    }
    let encodedServerAddress = encodeURIComponent(serverAddress);
    const chatLink = localStorage.getItem('chat_link');
    let defaultUrl;

    if (chatLink) {
      defaultUrl =
        chatLink + `/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    } else {
      defaultUrl = `https://chat.oneapi.pro/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    }
    let url;
    switch (type) {
      case 'ama':
        url = `ama://set-api-key?server=${encodedServerAddress}&key=sk-${key}`;
        break;

      case 'opencat':
        url = `opencat://team/join?domain=${encodedServerAddress}&token=sk-${key}`;
        break;

      default:
        url = defaultUrl;
    }

    window.open(url, '_blank');
  };

  const { loading, refresh } = useRequest(
    () =>
      API.get(
        `/api/token/?p=${
          activePage - 1
        }&size=${pageSize}&keyword=${searchKeyword}&user_id=${selectUserid}`
      ),
    {
      throttleWait: 300,
      refreshDeps: [selectUserid, searchKeyword, activePage, pageSize],
      onSuccess: (res) => {
        const { success, message, data, total } = res.data;
        if (success) {
          setTokens(data || []);
          // setActivePage(1);
          setTokenCount(total || 0);
        } else {
          showError(message);
        }
      },
    }
  );

  useEffect(() => {
    if (isAdminUser) getUserlist();
  }, [getUserlist, isAdminUser, user.id]);

  const manageToken = useCallback(
    async (id, action, record) => {
      let data = { id };
      let res;
      switch (action) {
        case 'delete':
          res = await API.delete(`/api/token/${id}`);
          break;
        case 'enable':
          data.status = 1;
          res = await API.put('/api/token/?status_only=true', data);
          break;
        case 'disable':
          data.status = 2;
          res = await API.put('/api/token/?status_only=true', data);
          break;
        default:
          break;
      }
      const { success, message } = res.data;
      if (success) {
        refresh();
        showSuccess('操作成功');
      } else {
        showError(message);
      }
    },
    [refresh]
  );

  const subDropdown = useCallback(
    (record) => (
      <Dropdown.Menu>
        <Dropdown.Item type='danger'>
          <Popconfirm
            title='确定是否要删除此令牌？'
            content='此修改将不可逆'
            okType={'danger'}
            position={'left'}
            onConfirm={() => {
              manageToken(record.id, 'delete', record);
            }}
          >
            删除
          </Popconfirm>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setEditingToken(record);
            setShowEdit(true);
          }}
        >
          编辑
        </Dropdown.Item>
        {record.status === 1 ? (
          <Dropdown.Item
            type='warning'
            onClick={() => {
              manageToken(record.id, 'disable', record);
            }}
          >
            禁用
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            type='secondary'
            onClick={() => {
              manageToken(record.id, 'enable', record);
            }}
          >
            启用
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    ),
    [manageToken]
  );

  const rowSelection = {
    onSelect: (record, selected) => {},
    onSelectAll: (selected, selectedRows) => {},
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRows);
    },
  };

  const handleRow = (record, index) => {
    if (record.status !== 1) {
      return {
        style: {
          background: 'var(--semi-color-disabled-border)',
        },
      };
    } else {
      return {};
    }
  };

  return (
    <>
      <EditToken
        refresh={refresh}
        editingToken={editingToken}
        visiable={showEdit}
        handleClose={closeEdit}
      ></EditToken>
      <Form
        layout='horizontal'
        style={{ marginTop: 10 }}
        labelPosition={'left'}
      >
        {isAdminUser && (
          <Form.Select
            field='Role'
            label='用户'
            placeholder='请选择用户'
            style={{ width: 176 }}
            optionList={userList}
            value={selectUserid}
            onChange={setSelectUserid}
          ></Form.Select>
        )}
        <Form.Input
          field='keyword'
          label='搜索关键字'
          placeholder='令牌名称'
          value={searchKeyword}
          onChange={setSearchKeyword}
        />
      </Form>

      <Table
        style={{ marginTop: 20 }}
        columns={columns}
        dataSource={tokens}
        pagination={{
          currentPage: activePage,
          pageSize: pageSize,
          total: tokenCount,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          onPageSizeChange: (size) => {
            setPageSize(size);
            setActivePage(1);
          },
          onPageChange: setActivePage,
        }}
        loading={loading}
        rowSelection={rowSelection}
        onRow={handleRow}
      ></Table>
      <Space>
        <Button
          theme='light'
          type='primary'
          style={{ marginRight: 8 }}
          onClick={() => {
            setEditingToken({
              id: undefined,
            });
            setShowEdit(true);
          }}
        >
          添加令牌
        </Button>
        <Button
          label='复制所选令牌'
          type='warning'
          onClick={async () => {
            if (selectedKeys.length === 0) {
              showError('请至少选择一个令牌！');
              return;
            }
            let keys = '';
            for (let i = 0; i < selectedKeys.length; i++) {
              keys +=
                selectedKeys[i].name + ' sk-' + selectedKeys[i].key + '\n';
            }
            await copyText(keys);
          }}
        >
          复制所选令牌到剪贴板
        </Button>
      </Space>
    </>
  );
};

export default TokensTable;
